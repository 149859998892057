import React, { useEffect, useState } from 'react';
import { Button, Form, Grid, GridColumn, GridRow, Header, Image, Segment, Statistic } from 'semantic-ui-react';
import { API, showError, showInfo, showSuccess } from '../../helpers';
import { renderQuota } from '../../helpers/render';
import styles from './TopUp.module.css';
import { Link } from 'react-router-dom';

const TopUp = () => {
  const [redemptionCode, setRedemptionCode] = useState('');
  const [topUpLink, setTopUpLink] = useState('');
  const [userQuota, setUserQuota] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const topUp = async () => {
    if (redemptionCode === '') {
      showInfo('请输入充值码！')
      return;
    }
    setIsSubmitting(true);
    try {
      const res = await API.post('/api/user/topup', {
        key: redemptionCode
      });
      const { success, message, data } = res.data;
      if (success) {
        showSuccess('充值成功！');
        setUserQuota((quota) => {
          return quota + data;
        });
        setRedemptionCode('');
      } else {
        showError(message);
      }
    } catch (err) {
      showError('请求失败');
    } finally {
      setIsSubmitting(false); 
    }
  };

  const openTopUpLink = () => {
    if (!topUpLink) {
      showError('超级管理员未设置充值链接！');
      return;
    }
    window.open(topUpLink, '_blank');
  };

  const getUserQuota = async ()=>{
    let res  = await API.get(`/api/user/self`);
    const {success, message, data} = res.data;
    if (success) {
      setUserQuota(data.quota);
    } else {
      showError(message);
    }
  }

  useEffect(() => {
    let status = localStorage.getItem('status');
    if (status) {
      status = JSON.parse(status);
      if (status.top_up_link) {
        setTopUpLink(status.top_up_link);
      }
    }
    getUserQuota().then();
  }, []);

  return (
    <Segment style={{ textAlign: "center" }}>
      {/*<Header as='h3'>充值额度</Header>*/}
      <Grid columns={1} stackable>
        <GridRow>
        <GridColumn>
          <div>
          <Statistic.Group widths='one'>
            <Statistic style={{padding: "10px"}}>
              <Statistic.Value>{renderQuota(userQuota)}</Statistic.Value>
            </Statistic>
          </Statistic.Group>
          </div>
          <div>
            <Button style={{
              background: 'none',
              border: 'none',
              boxShadow: 'none',
              padding: "6px",
              textDecoration: 'underline' // 添加下划线
            }} as={Link} to="/log" basic>使用明细</Button>
          </div>
          <div>
          <Form>
            <Form.Input
              placeholder='请输入兑换码'
              style={{width: "40%", paddingTop: "10px"}}
              name='redemptionCode'
              value={redemptionCode}
              onChange={(e) => {
                setRedemptionCode(e.target.value);
              }}
            />
            <Button color='green' onClick={topUp} disabled={isSubmitting}>
              {isSubmitting ? '兑换中...' : '兑换'}
            </Button>
          </Form>
          </div>
          <div>
          <Statistic.Group widths='one'>
            <Statistic style={{padding: "15px"}}>
              <Statistic.Label style={{color:"#797979"}}>联系下方工作人员获取兑换码</Statistic.Label>
            </Statistic>
          </Statistic.Group>
          </div>
        </GridColumn>
        </GridRow>
        <GridRow style={{padding: "60px"}}>
          <GridColumn>
          <Header as='h3'> ㅡ ㅡ  联系  ㅡ ㅡ</Header>
          <Statistic.Group widths='one'>
            <Statistic>
              <Statistic.Label style={{color:"#797979", textTransform: 'lowercase'}}>遇到支付问题可以联系邮箱bob@airouter.cloud, 或者添加下方微信</Statistic.Label>
            </Statistic>
          </Statistic.Group>
          <div style={{
            display: "flex",
            justifyContent: "center", /* 水平居中 */
            marginTop: "16px"
          }}>
            <Image src='https://airouter-1310733054.cos.na-siliconvalley.myqcloud.com/bob.png' size='medium'/>
          </div>

</GridColumn>
        </GridRow>
      </Grid>
    </Segment>
  );
};

export default TopUp;