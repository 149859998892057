import "./index.css"
import { Segment } from 'semantic-ui-react';
import React from 'react';
const markDownText = `## AIRouter隐私政策

最后更新：2023-12-23

本隐私政策描述了AIRouter（以下简称“我们”）在您访问或使用我们的服务时，如何收集、使用及披露关于您的信息。

1. 个人信息的收集
   我们收集并储存您选择与我们分享的个人识别信息，例如您的电子邮件地址或钱包地址。我们还收集与您使用我们的服务相关的信息，例如与模型请求相关联的Web应用程序URL。
2. 用户可以选择退出日志提示和完成情况的记录，这些用于改进匿名分析特性，例如分类。账户页面的分析部分可控制此偏好设置。用户还可以通过联系我们的bob@airouter.cloud地址请求删除提示和完成情况。
3. 信息的使用
   我们出于多种目的使用我们收集的信息，包括：
   (1) 建立和维护您的账户
   (2) 改进我们的服务
   (3) 计费目的
4. 信息共享与披露
   我们不会与第三方分享您的个人信息，除非是某些服务提供商，包括分析服务（如Google Analytics）和第三方AI服务提供商（如OpenAI和Azure）。这些第三方只能在必要时访问您的信息，且不允许将其用于其他目的。
5. 用户权利与选择
   您可以通过我们平台上的用户界面更新可修改的个人信息。如果您希望请求删除您的个人数据，请使用我们的bob@airouter.cloud地址与我们联系。
6. Cookies与其他跟踪技术
   我们使用cookies来存储有关您的会话信息，以增强您在我们平台上的体验。使用我们的服务，即表示您同意我们使用这些方法。
7. 数据传输
   我们不会将您的个人数据进行国际传输。
8. 针对儿童的政策
   我们的服务不针对13岁以下的儿童，我们不会故意收集13岁以下儿童的个人信息。如果我们得知我们收集了13岁以下儿童的个人信息，我们将采取措施从我们的记录中删除此类信息。
9. 隐私政策的更改
   我们可以随时修改本隐私政策，恕不另行通知。您继续使用我们的服务，即表示您接受并同意这些更改。
10. 联系我们
    如果您对本隐私政策或我们的做法有任何疑问或担忧，请通过邮件联系我们。
11. 终止
    我们可以随时出于任何原因终止您使用服务的权利，包括违反服务条款，在通知您后生效。您可以通过停止使用我们的服务随时终止您对服务的使用。

终止后，所有权利和义务将被消除，除了根据其性质应当存续的权利和义务，包括但不限于所有权条款、保证声明、责任限制和争议解决条款。
`

const Privacy = () => {


  return (
    <div style={{marginLeft: "16%", marginRight:"16%"}}>
      <Segment className="no-border-segment">
        <h1 className="text-center">AIRouter隐私政策</h1>
        <p className="text-center">最后更新：2023-12-23</p>
        <p className="text-center" style={{marginLeft: "16%", marginRight:"16%"}}>本隐私政策描述了AIRouter（以下简称“我们”）在您访问或使用我们的服务时，如何收集、使用及披露关于您的信息。</p>
        <h3>1. 个人信息的收集</h3>
        <p>   
          我们收集并储存您选择与我们分享的个人识别信息，例如您的电子邮件地址或钱包地址。我们还收集与您使用我们的服务相关的信息，例如与模型请求相关联的Web应用程序URL。
        </p>
        <p>
          用户可以选择退出日志提示和完成情况的记录，这些用于改进匿名分析特性，例如分类。账户页面的分析部分可控制此偏好设置。用户还可以通过联系我们的bob@airouter.cloud地址请求删除提示和完成情况。
        </p>
        <h3>2. 信息的使用</h3>
        <p>
          我们出于多种目的使用我们收集的信息，包括：
          <ol>
          <li>建立和维护您的账户</li>
          <li>改进我们的服务</li>
          <li>计费目的</li>
          </ol>
        </p>
        <h3>3. 信息共享与披露</h3>
        <p>
        我们不会与第三方分享您的个人信息，除非是某些服务提供商，包括分析服务（如Google Analytics）和第三方AI服务提供商（如OpenAI和Azure）。这些第三方只能在必要时访问您的信息，且不允许将其用于其他目的。
        </p>
        <h3>4. 用户权利与选择</h3>
        <p>
        您可以通过我们平台上的用户界面更新可修改的个人信息。如果您希望请求删除您的个人数据，请使用我们的bob@airouter.cloud地址与我们联系。
        </p>
        <h3>5. Cookies与其他跟踪技术</h3>
        <p>我们使用cookies来存储有关您的会话信息，以增强您在我们平台上的体验。使用我们的服务，即表示您同意我们使用这些方法。</p>
        <h3>6. 数据传输</h3>
        <p>我们不会将您的个人数据进行国际传输。</p>
        <h3>7. 针对儿童的政策</h3>
        <p>我们的服务不针对13岁以下的儿童，我们不会故意收集13岁以下儿童的个人信息。如果我们得知我们收集了13岁以下儿童的个人信息，我们将采取措施从我们的记录中删除此类信息。</p>
        <h3>8. 隐私政策的更改</h3>
        <p>我们可以随时修改本隐私政策，恕不另行通知。您继续使用我们的服务，即表示您接受并同意这些更改。</p>
        <h3>9. 联系我们</h3>
        <p>如果您对本隐私政策或我们的做法有任何疑问或担忧，请通过邮件联系我们。</p>
        <h3>10. 终止</h3>
        <p>我们可以随时出于任何原因终止您使用服务的权利，包括违反服务条款，在通知您后生效。您可以通过停止使用我们的服务随时终止您对服务的使用。</p>
        <p>终止后，所有权利和义务将被消除，除了根据其性质应当存续的权利和义务，包括但不限于所有权条款、保证声明、责任限制和争议解决条款。</p>
      </Segment>
    </div>
  )
};

export default Privacy