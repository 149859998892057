import { Segment } from 'semantic-ui-react';
import { marked } from 'marked';
import React from 'react';
import "./index.css"

const markDownText = `## 用户协议

AIRouter用户服务协议

最后更新：2023-12-23

本协议（“协议”）由您（“用户”或“开发者”）与AIRouter（以下简称“公司”、“我们”）签订。您使用我们的服务即表示同意这些条款。请仔细阅读。

1. 服务
   AIRouter是一个人工智能模型聚合器，提供API（“服务”）。API可以被开发者用来与多种大型语言模型、生成图像模型和生成3D对象模型互动。
2. AIRouter目前处于测试阶段；我们可能随时更改或停止服务的任何部分，包括特定模型的可用性和价格。
3. 如果需要移除官方的AIRouter模型，您可以期望以下情况：
   (1) 我们将通过AIRouter微信客服或者邮件提供通知。
   (2) 在一定时间内，我们将把对该模型的请求重定向到另一个价格和能力类似的模型。
   (3) 在至少2周的宽限期后，我们将停止重定向对该模型的请求，并返回404错误。
4. 义务
   禁止任何13岁以下的人使用AIRouter。您声明您已达到您所在司法管辖区的法定成年年龄，或者如果您未达到，您的父母或法定监护人必须同意这些AIRouter服务条款，并确认他们接受您代表他们的这些条款，并对您的使用承担责任。通过AIRouter访问的机器人可能产生不适合未成年人的内容。如果您代表他人或实体接受这些AIRouter服务条款，您确认您有权力将该人或实体绑定至这些条款。
5. 您同意不使用AIRouter来：
   (1) 侵犯他人权利或任何适用法律；
   (2) 违反这些条款或我们的政策，或OpenRouter支持模型的第三方提供商的政策，这些政策可以通过OpenAI等访问文档找到；
   (3) 反向组装、反向编译、反编译、翻译或以其他方式试图发现模型、算法和系统的源代码或AIRouter及其底层技术；
   (4) 滥用、伤害、干扰、逆向工程或破坏AIRouter、模型或其底层技术，例如通过以欺诈或欺骗的方式访问或使用它们，引入恶意软件，或绕过任何保护措施；
   (5) 以禁止的方式使用AIRouter或模型，例如超过我们不时设定的任何速率限制，或反复创建账户而不支付访问费用；
   (6) 使用任何方法从AIRouter提取数据，包括网络抓取、网络收割或网络数据提取方法，除非被这些AIRouter服务条款允许；
   (7) 声称AIRouter生成的内容是人类生成的。
6. 支付
   开发者需要支付模型费用，模型的定价在首页可见，根据模型不同和是否适用折扣而有所不同。
   (1) 用户可以联系客服充值获取兑换码，或者使用海外支付卡来支付模型费用。
   (2) AIRouter使用Stripe安全处理非加密支付。请参阅他们的服务条款。Stripe的支付手续费用为3% + 单笔$0.3。用户需自行承担交易费用
   (3) 如果我们收到您的支付的欺诈争议或任何与欺诈相关的退款，您将可能面临账号封禁，禁止对账户余额进行处理
   (4) 积分除非遇到网站停运，Beta模型关闭等不可抗力因素不可退款。
7. 如果您是企业并希望为每笔支付获得发票，Stripe将收取额外费用为每张发票0.4%，每笔交易上限为$2。
8. 我们可能随时更改定价。如果我们这样做，我们将在AIRouter网站或服务上发布更改通知。
9. 知识产权
   使用我们的服务不会将任何知识产权转让给用户或开发者。所有权利、所有权和利益，包括任何软件、技术或流程，均归您在AIRouter中上传、发布或展示的所有材料，以及您对模型可访问的提示（或其他输入）响应所收到的结果（“模型生成内容”），将被统称为“您的内容”。您保留对您的内容的所有权。
10. 争议解决
    任何因这些服务条款引起或与之相关的争议，或您与AIRouter根据这些服务条款的关系，均应通过具有约束力的仲裁解决。本协议受香港法律管辖，并依据其法律解释，不考虑任何法律冲突原则。
11. 隐私政策
    我们的隐私政策是一个单独的文件，解释了我们如何收集、使用和保护用户信息。它构成这些服务条款的一部分，并在此通过引用并入。
12. 风险
    用户应该充分了解大模型本身存在的不稳定性现象，包含OpenAI服务器偶尔宕机，API出现访问BUG等等，用户应对风险自行做好兜底处理，AIRouter不承担对应损失的责任。

13. 责任
    AIRouter对因使用其服务引起或与之相关的任何损害、损失或问题不承担责任。
14. 修改
    我们可能随时修改这些服务条款，恕不另行通知。您继续使用我们的服务，即表示您接受并同意这些更改。
15. 终止
    我们可能随时出于任何原因终止您使用服务的权利，包括违反这些服务条款，在通知您后生效。您可以通过停止使用我们的服务随时终止您对服务的使用。
16. 终止后，所有权利和义务将被消除，除了根据其性质应当存续的权利和义务，包括但不限于所有权条款、保证声明、责任限制和争议解决条款。
`

const UserPrivacy = () => {

  return (
  <div style={{marginLeft: "16%", marginRight:"16%"}}>
      <Segment className="no-border-segment">
        <h1 className="text-center">用户协议</h1>
        <p className="text-center">最后更新：2023-12-23</p>
        <h3>1. 服务</h3>
        <p>AIRouter是一个人工智能模型聚合器，提供API（“服务”）。API可以被开发者用来与多种大型语言模型、生成图像模型和生成3D对象模型互动。</p>
        <p>AIRouter目前处于测试阶段；我们可能随时更改或停止服务的任何部分，包括特定模型的可用性和价格。</p>
        <p>如果需要移除官方的AIRouter模型，您可以期望以下情况：
          <ol>
            <li> 我们将通过AIRouter微信客服或者邮件提供通知。</li>
            <li>在一定时间内，我们将把对该模型的请求重定向到另一个价格和能力类似的模型。</li>
            <li> 在至少2周的宽限期后，我们将停止重定向对该模型的请求，并返回404错误。</li>
          </ol>
        </p>
        <h3>2. 义务</h3>
        <p>
          禁止任何13岁以下的人使用AIRouter。您声明您已达到您所在司法管辖区的法定成年年龄，或者如果您未达到，您的父母或法定监护人必须同意这些AIRouter服务条款，并确认他们接受您代表他们的这些条款，并对您的使用承担责任。通过AIRouter访问的机器人可能产生不适合未成年人的内容。如果您代表他人或实体接受这些AIRouter服务条款，您确认您有权力将该人或实体绑定至这些条款。
        </p>
        <p>
          您同意不使用AIRouter来：
          <ol>
            <li> 侵犯他人权利或任何适用法律；</li>
            <li>违反这些条款或我们的政策，或OpenRouter支持模型的第三方提供商的政策，这些政策可以通过OpenAI等访问文档找到；</li>
            <li> 反向组装、反向编译、反编译、翻译或以其他方式试图发现模型、算法和系统的源代码或AIRouter及其底层技术；</li>
            <li> 滥用、伤害、干扰、逆向工程或破坏AIRouter、模型或其底层技术，例如通过以欺诈或欺骗的方式访问或使用它们，引入恶意软件，或绕过任何保护措施；</li>
            <li> 以禁止的方式使用AIRouter或模型，例如超过我们不时设定的任何速率限制，或反复创建账户而不支付访问费用；</li>
            <li> 使用任何方法从AIRouter提取数据，包括网络抓取、网络收割或网络数据提取方法，除非被这些AIRouter服务条款允许；</li>
            <li> 声称AIRouter生成的内容是人类生成的。</li>
          </ol>
        </p>
        <h3>3. 支付</h3>
        <p>开发者需要支付模型费用，模型的定价在首页可见，根据模型不同和是否适用折扣而有所不同。
          <ol>
            <li> 用户可以联系客服充值获取兑换码，或者使用海外支付卡来支付模型费用。</li>
            <li> AIRouter使用Stripe安全处理非加密支付。请参阅他们的服务条款。Stripe的支付手续费用为3% + 单笔$0.3。用户需自行承担交易费用</li>
            <li> 如果我们收到您的支付的欺诈争议或任何与欺诈相关的退款，您将可能面临账号封禁，禁止对账户余额进行处理</li>
            <li> 积分除非遇到网站停运，Beta模型关闭等不可抗力因素不可退款。</li>
          </ol>
          <p>如果您是企业并希望为每笔支付获得发票，Stripe将收取额外费用为每张发票0.4%，每笔交易上限为$2。</p>
          <p>我们可能随时更改定价。如果我们这样做，我们将在AIRouter网站或服务上发布更改通知。</p>
        </p>
        <h3>4. 知识产权</h3>
        <p>
          使用我们的服务不会将任何知识产权转让给用户或开发者。所有权利、所有权和利益，包括任何软件、技术或流程，均归您在AIRouter中上传、发布或展示的所有材料，以及您对模型可访问的提示（或其他输入）响应所收到的结果（“模型生成内容”），将被统称为“您的内容”。您保留对您的内容的所有权。
        </p>
        <h3>5. 争议解决</h3>
        <p>
        任何因这些服务条款引起或与之相关的争议，或您与AIRouter根据这些服务条款的关系，均应通过具有约束力的仲裁解决。本协议受香港法律管辖，并依据其法律解释，不考虑任何法律冲突原则。
        </p>
        <h3>6. 隐私政策</h3>
        <p>我们的隐私政策是一个单独的文件，解释了我们如何收集、使用和保护用户信息。它构成这些服务条款的一部分，并在此通过引用并入。</p>
        <h3>7. 风险</h3>
        <p>
          用户应该充分了解大模型本身存在的不稳定性现象，包含OpenAI服务器偶尔宕机，API出现访问BUG等等，用户应对风险自行做好兜底处理，AIRouter不承担对应损失的责任。
        </p>
        <h3>8. 责任</h3>
        <p>AIRouter对因使用其服务引起或与之相关的任何损害、损失或问题不承担责任。</p>
        <h3>9. 修改</h3>
        <p>我们可能随时修改这些服务条款，恕不另行通知。您继续使用我们的服务，即表示您接受并同意这些更改。</p>
        <h3>10. 终止</h3>
        <p>我们可能随时出于任何原因终止您使用服务的权利，包括违反这些服务条款，在通知您后生效。您可以通过停止使用我们的服务随时终止您对服务的使用。</p>
        <p>终止后，所有权利和义务将被消除，除了根据其性质应当存续的权利和义务，包括但不限于所有权条款、保证声明、责任限制和争议解决条款。</p>
      </Segment>
  </div>
  )
};

export default UserPrivacy